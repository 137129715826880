export const config = {
  content: {
    name: 'Raman Marozau',
    role: 'Tech Innovator & Paradigm Creator',
    link: {
      linkedIn: 'https://www.linkedin.com/in/raman-marozau/',
      email: 'sa.marozau@gmail.com',
      skype: 'morozowroman',
    }
  },
  s3: {
    route: 'https://cv-solution-architect-files.s3.eu-central-1.amazonaws.com',
    filename: 'CV+–+Raman+Marozau.pdf',
  },
  colors: {
    basic: '#002e30',
  },
  domain: window.location.hostname,
  protocol: 'https',
}
