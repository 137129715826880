import { config } from '../config';

export type Publication = {
  title: string;
  description: string;
  date: Date;
  logo: string;
  link: string;
};

export const PUBLICATION_LIST: Publication[] = [
  {
    title: "Theory of the Essence: A New Paradigm for Scientific Exploration of Reality.",
    logo: `${config.s3.route}/doi.logo.png`,
    date: new Date(2025, 0, 10),
    link: 'https://doi.org/10.31219/osf.io/t49fj',
    description: 'This manuscript introduces Conscious Anarchy, a philosophy designed to align human society with principles of sustainability, equity, and cooperation. It addresses the flaws of current systems – control, inequality, and unsustainable hierarchies – by proposing a transformative framework grounded in decentralized governance, ethical resource distribution, and the responsible use of technology. Conscious Anarchy fosters a gradual awakening to humanity’s interconnectedness. It emphasizes practical steps such as integrating empathy into education, testing decentralized governance models, and leveraging technology for transparent resource management. While full transformation may take generations, immediate action is essential. By embracing balance and cooperation, humanity can avert systemic collapse and create a sustainable, equitable future. Conscious Anarchy invites individuals, communities, and institutions to contribute to this shared vision of lasting harmony and collective growth.',
  },
  {
    title: "Semantic-Driven Task Allocation: Elevating Multi-Agent Systems with Language Models.",
    logo: `${config.s3.route}/doi.logo.png`,
    date: new Date(2025, 0, 5),
    link: 'https://doi.org/10.36227/techrxiv.173611620.02896776/v2',
    description: 'Modern multi-agent systems (MAS) often rely on static task allocation methods, which are insufficient for dynamic environments requiring continuous task reassignment and optimization. This paper introduces a theoretical framework that integrates fine-tuned large language models (LLMs) into MAS to enable semantic task evaluation and dynamic task distribution. By analyzing language-based task states, the system identifies hidden dependencies and adapts task allocation in real time. While the proposed system is in the conceptual phase, it offers a promising solution for automating complex workflows, reducing human intervention, and improving scalability in dynamic environments. Future work will focus on prototyping and validating the framework in real-world scenarios.',
  },
  {
    title: "Dynamic Task Evaluation: A Language-Based Approach for Adaptive Multi-Agent Systems.",
    logo: `${config.s3.route}/doi.logo.png`,
    date: new Date(2025, 0, 5),
    link: 'https://doi.org/10.36227/techrxiv.173611616.64880965/v2',
    description: 'This paper introduces an innovative approach to task completion analysis in multi-agent systems using large language models (LLMs). Unlike traditional methods, which rely on binary indicators of task completion, our approach emphasizes iterative, context-driven task distribution and execution. Tasks are dynamically redistributed by the Centralized Control Unit (CCU), which operates as a coordinator rather than a final decision-maker on task completion. The thematic context of the first incoming task serves as a guiding framework, allowing agents to iteratively refine their operations and share progress through language-based outputs. The system leverages LLM-generated insights to assess task states, recommend further actions, and dynamically adapt task assignments. Task completion is not determined by static metrics but through linguistic confirmations and iterative feedback loops. This adaptive framework ensures unresolved tasks continue to circulate until a comprehensive resolution is achieved, enabling seamless management of evolving and interdependent tasks in multi-agent environments. The proposed approach offers significant advantages in dynamic environments by fostering adaptability, mitigating bottlenecks, and providing a scalable solution for managing complex, context-dependent tasks across various domains.',
  },
  {
    title: "The Awakening Society: Conscious Anarchy as a New Beginning.",
    logo: `${config.s3.route}/doi.logo.png`,
    date: new Date(2025, 0, 10),
    link: 'https://doi.org/10.31219/osf.io/wsryf',
    description: 'This manuscript introduces Conscious Anarchy, a philosophy designed to align human society with principles of sustainability, equity, and cooperation. It addresses the flaws of current systems – control, inequality, and unsustainable hierarchies – by proposing a transformative framework grounded in decentralized governance, ethical resource distribution, and the responsible use of technology. Conscious Anarchy fosters a gradual awakening to humanity’s interconnectedness. It emphasizes practical steps such as integrating empathy into education, testing decentralized governance models, and leveraging technology for transparent resource management. While full transformation may take generations, immediate action is essential. By embracing balance and cooperation, humanity can avert systemic collapse and create a sustainable, equitable future. Conscious Anarchy invites individuals, communities, and institutions to contribute to this shared vision of lasting harmony and collective growth.',
  },
  {
    title: 'Theory of the Essence: A New Paradigm for Understanding Reality',
    description: 'The Theory of the Essence introduces a revolutionary framework that redefines our understanding of the fundamental nature of reality, time, and existence. By proposing the concept of Essence as the core structure that unites all aspects of reality — past, present, and future — this theory addresses not only the mechanics of the universe but also the deeper question of why reality exists in its current form. Essence is the ultimate foundation of reality, and its intrinsic striving toward truth guides the unfolding of time and existence toward greater understanding and realization. This theory has profound implications for both scientific and philosophical inquiry, providing new avenues for research into the nature of time, causality, and the evolution of truth.',
    date: new Date(2024, 8, 27),
    logo: `/academia-logo.png`,
    link: 'https://www.academia.edu/124232327/Theory_of_the_Essence_A_New_Paradigm_for_Understanding_Reality',
  },
  {
    title: 'Versioning Automation Management Tool',
    description: 'You have always been interested what does the version of the product mean and how to manage these versions. Let’s consider these questions.',
    date: new Date(2018, 11, 29),
    logo: `${config.s3.route}/reddit-logo.png`,
    link: 'https://www.reddit.com/r/opensource/comments/aaewcn/versioning_automation_management_tool/',
  },
  {
    title: 'Инструмент автоматизации управления версиями',
    description: 'Всегда было интересно, что такое версии продукта и как ими управлять? Как автоматизировать управление версиями разработки? Постараемся ответить на эти вопросы.',
    date: new Date(2018, 11, 21),
    logo: `${config.s3.route}/habr-logo.png`,
    link: 'https://habr.com/en/articles/433994/', // @todo: publication link
  },
];
